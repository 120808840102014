import getProductDomainUtils from "./utils/get-product-domain.utils";

export const MAGNOLIA_BASE_URL = process.env.NEXT_PUBLIC_MGNL_HOST || "";
export const MAGNOLIA_ANNOTATION_URL = `${MAGNOLIA_BASE_URL}/.rest/template-annotations/v1`;
export const MAGNOLIA_PHONE_NUMBER_URL = `${MAGNOLIA_BASE_URL}/.rest/delivery/brochure/phone-numbers/v1`;
export const MAGNOLIA_PAGES_URL = `${MAGNOLIA_BASE_URL}/.rest/pages/v1`;
export const DYNAMIC_FOOTER_URL = `${MAGNOLIA_BASE_URL}/.rest/delivery/dynamicFooters`;
export const NOT_FOUND_URL = `${MAGNOLIA_BASE_URL}/.rest/delivery/notFounds`;
export const CONTENT_PATH: string =
  process.env.NEXT_PUBLIC_MGNL_SITE_PATH ?? "";
export const language: string = "en";
export const HELPOVERLAY_URL = `${MAGNOLIA_BASE_URL}/.rest/delivery/helpoverlays`;
export const QUOTE_DASHBOARD_URL = `${MAGNOLIA_BASE_URL}/.rest/delivery/quoteDashBoards`;
export const MAIN_PAGE_URL =
  process.env.NEXT_PUBLIC_BROCHURE_URI || `https://www.staysure.co.uk/`;
export const AVT_MAIN_PAGE_URL = `https://www.avantitravelinsurance.co.uk/`;
export const EXPAT_MAIN_PAGE_URL = `https://www.staysure.com/`;
export const ENVIRONMENT = process.env.NEXT_PUBLIC_ENV || "production";
export const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL;
export const ADDRESS_API_URL = process.env.NEXT_PUBLIC_ADDRESS_API_URL;
export const LOGIN_PAGE_URL = `https://my.staysure.co.uk/signin`;
export const PAYMENT_URL = `${MAGNOLIA_BASE_URL}/.rest/delivery/payments`;
export const EXPAT_PAGE_URL = `http://www.staysure.com/`;
export const QA_ENVIRONMENT = `https://quotebff.api.sae.qa.staysuregroup.com/`;

export const ADDRESS_API_KEY = process.env.NEXT_PUBLIC_ADDRESS_API_KEY;
export const BFF_API_KEY = process.env.NEXT_PUBLIC_BFF_API_KEY;
export const OPTIMIZELY_ID = process.env.NEXT_PUBLIC_OPTIMIZELY_ID ?? "";
export const ZDASSET_ID = process.env.NEXT_PUBLIC_ZDASSET_ID || "";

export const SINGLE_TRIP = "SINGLE_TRIP";
export const ANNUAL_MULTI_TRIP = "ANNUAL_MULTI_TRIP";

export const BASIC = "BASIC";
export const COMPREHENSIVE = "COMPREHENSIVE";
export const SIGNATURE = "SIGNATURE";

export const ESSENTIALS = "ESSENTIALS";
export const CLASSIC = "CLASSIC";
export const DELUXE = "DELUXE";

export const PAY_AT_ONCE = "PAY_AT_ONCE";
export const SPREAD_THE_COST = "SPREAD_THE_COST";

export const PRODUCT = process.env.NEXT_PUBLIC_PRODUCT ?? "staysure";
export const SECONDARY_PRODUCT =
  process.env.NEXT_PUBLIC_SECONDARY_PRODUCT ?? "";

export const PRODUCT_FOOTER_DOMAIN = SECONDARY_PRODUCT ?? PRODUCT;

export const STS_MYACCOUNT_LOGIN_URL = `https://my.staysure.co.uk/`;
export const PRODUCT_DOMAIN = getProductDomainUtils(PRODUCT, SECONDARY_PRODUCT); // API domain

export const DASHBOARD_ENABLED =
  process.env.NEXT_PUBLIC_DASHBOARD_ENABLED || "false";

export const MEDICAL_FLOW_V2_ENABLED =
  process.env.NEXT_PUBLIC_MEDICAL_FLOW_V2_ENABLED || "false";

export const AVT_MYACCOUNT_LOGIN_URL = `https://my.avantitravelinsurance.co.uk/`;

export const TERMS_OF_BUSINESS = "Terms of business";
export const TERMS_OF_BUSINESS_URL =
  "https://www.staysure.co.uk/terms-of-business/";

// Verisk Library
export const VERISK_BASE_URL = process.env.NEXT_PUBLIC_VERISK_BASE_URL || "";
export const VERISK_URL = `${VERISK_BASE_URL}/api/v1`;
export const VERISK_KEY = process.env.NEXT_PUBLIC_VERISK_BFF_API_KEY || "";
export const COMPONENT_V3_STAYSURE_BRAND = "sts";
export const COMPONENT_V3_AVANTI_BRAND = "avt";
// brand
export const STYLESHEET = "stylesheet";
export const BRAND_AVANTI = "avanti";
export const AVANTI_FONT = {};
export const AMT_POLICY_START_DATE_MAX_DAYS = 90;
export const MAX_ADDRESS_LENGTH = 60;
export const MOBILE_BREAKPOINT = 600;
