import { MedicalQuestionType } from "@/utils/schema/gatekeeper-question-page-schema.utils";

export const LandingPage = "eligibility";
export const QuestionOne = "cover-type";
export const QuestionTwo = "travelling-from";
export const QuestionThree = "travelling-to";
export const QuestionFour = "cruise";
export const QuestionSeven = "organiser";
export const QuestionEight = "travellers";
export const QuestionFive = "travel-dates";
export const QuestionSix = "cover-for";
export const QuestionMedical = "question-medical";
export const QuestionMedicalConfirmation = "medical-confirmation";
export const QuestionMedicalConfirmationv2 = "medical-confirmation-v2";
export const QuestionMedicalConditions = "medical-conditions";
export const QuestionMedicalTreatments = "medical-treatments";
export const QuestionMedicalTreatmentsv2 = "medical-treatments-v2";
export const MedicalScreen = "medical-screen";
export const MainView = "main-view";
export const QuestionDisable = "question-disable";
export const MedicalLandingPage = "traveller-health";
export const MedicalLanding = "medical-landing";
export const MedicalDashboard = "medical-dashboard";
export const MedicalNothingToDeclare = "medical-no-declare";
export const NotFound = "/not-found";
export const ContactDetails = "contact-details";
export const Address = "address";
export const Review = "review";
export const QuoteDecline = "unable-to-quote";
export const PostalSettings = "PostalSettings";
export const QuoteReview = "quote-review";
export const ReviewAutoRenewal = "review-auto-renewal";
export const Quote = "quote";
export const YouAreNowCovered = "you-are-now-covered";
export const Payment = "payment";
export const PolicyDocument = "policy-document";

export const RenewalPage = "renewal";

export const SessionSuccessPage = "SuccessPage";

// question two const
export const SingleTrip = "SINGLE_TRIP";
export const AnnualMultiTrip = "ANNUAL_MULTI_TRIP";
export const NotSure = "NOT_SURE";

export const UKOnly = "UK";
export const Europe = "EUROPE";
export const Worldwide = "WORLD";
export const EuropeHR = "EUROPE_HR";
export const EuropeLR = "EUROPE_LR";
export const WorldwideHR = "WORLD_HR";
export const WorldwideLR = "WORLD_LR";
export const UK1 = "UK1";
export const UK3 = "UK3";
export const UK4 = "UK4";
export const Spain = "spain";
export const France = "france";
export const Portugal = "portugal";
export const UK5 = "UK5";
export const SPAIN_ES = "ES";
export const FRANCE_FR = "FR";
export const PORTUGAL_PT = "PT";

// cover type

export const TrustpilotAPIKey = process.env.NEXT_PUBLIC_TRUST_PILOT_API_KEY;

// question four
export const SameLocation = "SameLocation";
export const SameDay = "SameDay";

// question five
export const Individual = "INDIVIDUAL";
export const Couple = "COUPLE";
export const Family = "FAMILY";
export const SingleParentFamily = "SINGLE_PARENT_FAMILY";
export const Group = "OTHER";

// Add Medical constants
export const QUOTE_JOURNEY_TYPE = "Quote";
export const QUOTE_CHANNEL_ONLINE = "ONLINE";
export const QUOTE_DOMAIN_STS = "STS";
export const MEDICAL_REQUEST_ADD = "ADD";
export const MEDICAL_REQUEST_EDIT = "EDIT";
export const MEDICAL_REQUEST_REDIRECT_URL = `${process.env.NEXT_PUBLIC_HOME_URI}/medical-dashboard`;
export const THIRDPARTYRESPONSE_TYPE = "Medical";

// edit quote constants
export const CruiseEdit = "cruiseEdit";
export const QuoteEdit = "editQuote";
export const DatesEdit = "datesEdit";
export const DestinationEdit = "destinatinEdit";
export const MedicalEdit = "medicalEdit";
export const TravellerEdit = "travellerEdit";
export const ContactEdit = "contactsEdit";
export const CoverEdit = "coverEdit";
export const OrganizerEdit = "organizerEdit";

// finish edit button
export const FinishEdit = "Finish editing";

export const CancelEdit = "Cancel editing";
export const ContinueBtn = "Continue";

// FCDO countries

export const fcdoCountryList = [
  "NE",
  "SO",
  "TD",
  "YE",
  "CF",
  "ML",
  "AF",
  "IQ",
  "LY",
  "SY",
  "BF",
  "IR",
  "AN",
  "RU",
  "LB",
  "UA",
  "BY",
  "HT",
  "SD",
  "VE",
  "PS",
  "IL",
];

export const medicalUsagePageQuestionTypes: Record<
  string,
  MedicalQuestionType
> = {
  "medical-confirmation": MedicalQuestionType.Confirmation,
  "medical-conditions": MedicalQuestionType.Conditions,
  "medical-treatments": MedicalQuestionType.Treatments,
};

export const travelerExceedingConfirmationModalTitle =
  "Maximum number of travelers reached";

export const travelerExceedingConfirmationModalDesc =
  "We are unable to cover more than 9 travelers online. If you require cover for more than 9 travelers, please call us on 0330 822 5939.";

export const travelerExceedingConfirmationModalCTABtnOne = "Continue";

export const travelerExceedingConfirmationModalCTABtnTwo = "Contact us";

export enum Brand {
  STAYSURE = "staysure",
  AVANTI = "avanti",
}
