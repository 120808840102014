function getProductDomain(prod: string, secondaryProd: string) {
  const products = {
    staysure: "STS",
    avanti: "AVN",
    expat: "EXP",
  };

  const brand: "staysure" | "avanti" | "expat" =
    secondaryProd === "expat"
      ? "expat"
      : ((prod as "staysure" | "avanti") ?? "staysure");

  return products[brand];
}

export default getProductDomain;
