import { PRODUCT, SECONDARY_PRODUCT } from "@/constants";

const isBCUKEnabled = {
  avanti: false,
  staysure: true,
  expat: false,
};

const brand: "staysure" | "avanti" | "expat" =
  SECONDARY_PRODUCT === "expat"
    ? "expat"
    : ((PRODUCT as "staysure" | "avanti") ?? "staysure");

const isBCUKEnabledByBrand = isBCUKEnabled[brand];

export default isBCUKEnabledByBrand;
