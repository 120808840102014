import { z } from "zod";

export enum MedicalQuestionType {
  Confirmation = "agreedConfirmation",
  Conditions = "agreedHistory",
  Treatments = "agreedTreatments",
}
export const trustPilotContent = z.object({
  trustpilotText: z.object({
    "0": z.object({
      richText: z.string(),
    }),
  }),
  trustpilotImage: z.object({
    "0": z.object({
      image: z.object({
        altText: z.string(),
        src: z.string(),
      }),
    }),
  }),
});

const MedicalQuestionTypeSchema = z.nativeEnum(MedicalQuestionType);

const QMedicalContainerPropsSchema = z.object({
  questionType: MedicalQuestionTypeSchema.optional(),
  questionText: z.string().optional(),
  questionInfoText: z.string().optional(),
  ctaButtonText: z.string().optional(),
  yesCtaButtonText: z.string().optional(),
  noCtaButtonText: z.string().optional(),
  buttonDisable: z.boolean().optional(),
  usagePage: z.string(),
  selectedAnswer: z.number().nullable(),
  setSelectedAnswer: z.function().args(z.number()).returns(z.void()).optional(),
  onSubmit: z.function().args(z.string()).returns(z.void()).optional(),
  onBack: z.function().args(z.string()).returns(z.void()).optional(),
  ctnBtnLoading: z.boolean().optional(),
});

const objectSchema = z.object({}).nullable();

const QMedicalPropsSchema = z
  .object({
    template: z.string().optional(),
    defaultDescription: objectSchema.optional(),
    answerYesDescription: objectSchema.optional(),
    answerNoDescription: objectSchema.optional(),
    answerNoUndiagnosedDescription: objectSchema.optional(),
    answerUndiagnosedQuestionDescription: objectSchema.optional(),
    defaultSupportDrawer: objectSchema.optional(),
    answerYesSupportDrawer: objectSchema.optional(),
    answerNoSupportDrawer: objectSchema.optional(),
    trustpilot: z.object({ "0": trustPilotContent.optional() }).optional(),
    defaultReadMoreButtonText: z.string().optional(),
    answerYesReadMoreButtonText: z.string().optional(),
    answerNoReadMoreButtonText: z.string().optional(),
    alertMessage: z.string().optional(),
    cancelEditingBtnText: z.string().optional(),
    finishEditingBtnText: z.string().optional(),
    removeMedicalConditionModalTitle: z.string().optional(),
    removeMedicalConditionModalDescription: z.string().optional(),
    removeMedicalConditionModalCTABtnOne: z.string().optional(),
    removeMedicalConditionModalCTABtnTwo: z.string().optional(),
    saveQuestionModalTitle: z.string().optional(),
    saveQuestionModalDescription: z.string().optional(),
    saveQuestionModalCTABtnOne: z.string().optional(),
    saveQuestionModalCTABtnTwo: z.string().optional(),
    undiagnosedQuestionEnabled: z.boolean().optional(),
    undiagnosedQuestionExperimentEnabled: z.boolean().optional(),
    questionInfoTextWithUndiagnosed: z.string().optional(),
    questionUndiagnosedText: z.string().optional(),
    questionUndiagnosedInfoText: z.string().optional(),
    yesUndiagnosedCtaButtonText: z.string().optional(),
    noUndiagnosedCtaButtonText: z.string().optional(),
    alertMessageUndiagnosed: z.string().optional(),
    defaultBackgroundImage: z
      .object({
        "@id": z.string(),
        "@link": z.string(),
        "@name": z.string(),
        "@path": z.string(),
        "@type": z.string(),
        "@uuid": z.string(),
      })
      .optional(),
    answerYesBackgroundImage: z
      .object({
        "@id": z.string(),
        "@link": z.string(),
        "@name": z.string(),
        "@path": z.string(),
        "@type": z.string(),
        "@uuid": z.string(),
      })
      .optional(),
    answerNoBackgroundImage: z
      .object({
        "@id": z.string(),
        "@link": z.string(),
        "@name": z.string(),
        "@path": z.string(),
        "@type": z.string(),
        "@uuid": z.string(),
      })
      .optional(),
    answerUndiagnosedBackgroundImage: z
      .object({
        "@id": z.string(),
        "@link": z.string(),
        "@name": z.string(),
        "@path": z.string(),
        "@type": z.string(),
        "@uuid": z.string(),
      })
      .optional(),
    answerNoUndiagnosedBackgroundImage: z
      .object({
        "@id": z.string(),
        "@link": z.string(),
        "@name": z.string(),
        "@path": z.string(),
        "@type": z.string(),
        "@uuid": z.string(),
      })
      .optional(),
  })
  .merge(
    QMedicalContainerPropsSchema.omit({
      questionType: true,
      buttonDisable: true,
      usagePage: true,
      selectedAnswer: true,
      setSelectedAnswer: true,
    })
  );

const qMedicalSectionData = z.object({
  medicalArea: z.array(QMedicalPropsSchema),
});

export type GatekeeperQuestion = z.infer<typeof QMedicalPropsSchema>;

export type GatekeeperQuestionPages = z.infer<typeof qMedicalSectionData>;
