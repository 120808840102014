/* eslint-disable prettier/prettier */
import {
  AnnualMultiTrip,
  Europe,
  EuropeHR,
  EuropeLR,
  Worldwide,
  WorldwideHR,
  WorldwideLR,
} from "@/config/app-constant";
import { PRODUCT_DOMAIN } from "@/constants";
import {
  MarketingChannelDetails,
  QuestionPayload,
  TripDetails,
} from "@/types/QuestionPayloadType";

import isBCUKEnabledByBrand from "./bcuk-enabled.util";

function selectLoationHookFlash(tripDetails: TripDetails | undefined) {
  let toLocation: string = "";
  if (tripDetails) {
    if (tripDetails?.policyType === AnnualMultiTrip) {
      toLocation = tripDetails?.area || "";
    } else {
      toLocation = tripDetails?.toLocation || "";
    }
  }

  let selectedLoation: {
    destination: string;
    destinationAdditions: string | undefined;
  } = {
    destination: toLocation,
    destinationAdditions: undefined,
  };

  if (toLocation) {
    if (toLocation === WorldwideHR) {
      selectedLoation = {
        destination: Worldwide,
        destinationAdditions: "true",
      };
    }
    if (toLocation === WorldwideLR) {
      selectedLoation = {
        destination: Worldwide,
        destinationAdditions: "false",
      };
    }
    if (toLocation === EuropeHR) {
      selectedLoation = {
        destination: Europe,
        destinationAdditions: "true",
      };
    }
    if (toLocation === EuropeLR) {
      selectedLoation = {
        destination: Europe,
        destinationAdditions: "false",
      };
    }
  }

  return selectedLoation;
}

const initialPayload: QuestionPayload = {
  type: "IntermediateData",
  channel: "ONLINE",
  attributes: {
    domain: PRODUCT_DOMAIN,
    lastVisitedPage: "test",
  },
};

export function modifyPayloadSave(
  payLoadResponse: QuestionPayload | null | undefined,
  tripDetailsModified?: TripDetails | undefined,
  marketingChannelDetails?: MarketingChannelDetails,
  isBCUK?: boolean
) {
  let payloadModify: QuestionPayload;
  if (payLoadResponse?.referenceId) {
    payloadModify = {
      ...payLoadResponse,
      attributes: {
        ...payLoadResponse.attributes,
        tripDetails: tripDetailsModified,
        marketingChannelDetails:
          (marketingChannelDetails && marketingChannelDetails) ||
          payLoadResponse?.attributes?.marketingChannelDetails,
        ...{
          partnerCode:
            isBCUK && isBCUKEnabledByBrand
              ? "BCUK"
              : payLoadResponse?.attributes?.partnerCode,
        },
      },
    };
  } else {
    payloadModify = {
      ...initialPayload,
      attributes: {
        ...initialPayload.attributes,
        organiser: payLoadResponse?.attributes.organiser,
        tripDetails: tripDetailsModified,
        marketingChannelDetails:
          (marketingChannelDetails && marketingChannelDetails) ||
          payLoadResponse?.attributes?.marketingChannelDetails,
        ...{
          partnerCode:
            isBCUK && isBCUKEnabledByBrand
              ? "BCUK"
              : payLoadResponse?.attributes?.partnerCode,
        },
      },
    };
  }

  return payloadModify;
}

export function modifyQuestionQuotePayload(
  payLoadResponse: QuestionPayload | null | undefined
) {
  try {
    const travellerDetails = {
      medicalTermsAndConditions:
        payLoadResponse?.attributes?.travellerDetails
          ?.medicalTermsAndConditions,
      traveller: payLoadResponse?.attributes?.travellerDetails?.traveller,
    };

    const modifiedTripDetails = payLoadResponse?.attributes?.tripDetails;
    if (!modifiedTripDetails?.toLocation) {
      delete modifiedTripDetails?.toLocation;
      delete modifiedTripDetails?.toLocationCountryDetails;
    }

    // if (
    //   payLoadResponse?.attributes?.organiser &&
    //   payLoadResponse?.attributes?.organiser?.travelling
    // ) {

    // }

    const quotoEditResponse = {
      type: payLoadResponse?.type || "",
      channel: "ONLINE",
      referenceId: payLoadResponse?.referenceId || "",
      version: payLoadResponse?.version || "",
      attributes: {
        domain: PRODUCT_DOMAIN,
        organiser: payLoadResponse?.attributes?.organiser,
        tripDetails: payLoadResponse?.attributes?.tripDetails,
        travellerDetails,
        marketingChannelDetails:
          payLoadResponse?.attributes?.marketingChannelDetails,
        partnerCode: payLoadResponse?.attributes?.partnerCode,
        experimentDetails: payLoadResponse?.attributes?.experimentDetails,
      },
    };

    return quotoEditResponse;
  } catch (error) {
    return null;
  }
}

export interface PageWindow extends Window {
  pageLoadUrls?: string[];
}

// eslint-disable-next-line consistent-return, @typescript-eslint/no-explicit-any
export function accessPagePreviousURL(window: any) {
  const extendedWindow =
    typeof window !== "undefined" && (window as PageWindow);
  let userReferrer = "";
  if (extendedWindow) {
    extendedWindow.pageLoadUrls = extendedWindow.pageLoadUrls ?? [];
    userReferrer =
      extendedWindow.pageLoadUrls.length > 1
        ? extendedWindow.pageLoadUrls[extendedWindow.pageLoadUrls.length - 2]
        : "";

    return userReferrer;
  }
}

// eslint-disable-next-line consistent-return, @typescript-eslint/no-explicit-any
export function getLastsuccessPage(window: any) {
  const extendedWindow =
    typeof window !== "undefined" && (window as PageWindow);
  let userPage = "";
  if (extendedWindow) {
    extendedWindow.pageLoadUrls = extendedWindow.pageLoadUrls ?? [];
    userPage =
      extendedWindow.pageLoadUrls.length > 1
        ? extendedWindow.pageLoadUrls[extendedWindow.pageLoadUrls.length - 1]
        : "";

    return userPage;
  }
}

export function isLastIndexIncludeSameValue(array: string[], value: string) {
  // Check if the array is empty
  if (array.length === 0) {
    return false;
  }

  // Get the last element of the array
  const lastElement = array[array.length - 1];

  // Compare the last element with the value
  return lastElement === value;
}
// export function setGTMHookFlashQuestionSet(
//   payLoadResponse: QuestionPayload,
//   page: string,
//   trigger: boolean
// ) {

//   return payloadModify;
// }

export function storageAvailable() {
  try {
    if (typeof window !== "undefined") {
      const storage = window.sessionStorage;
      const x: string = "__storage_test__";
      storage.setItem(x, x);
      storage.removeItem(x);
      return true;
    }
  } catch (e) {
    return false;
  }
}

export const getCookieValue = (name: string, cookie: unknown) => {
  return (
    (cookie &&
      (cookie as string)
        .match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")
        ?.pop()) ||
    ""
  );
};

export default selectLoationHookFlash;
